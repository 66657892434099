import { Component } from "react";
import { Link } from "react-router-dom";
import { SaticAssets } from "../../../Constants";

class CategoryItem extends Component {
  setErrorImg = (el) => {
    el.src = SaticAssets.IMG_ERROR_PLACEHOLDER;
  };

  render() {
    const { item } = this.props;
    return (
      //   <Link
      //     to={"/categoryproducts/" + item.id}
      //     data-parentid={item.id}
      //     className="cards-sm popular-categories__wrapper-item"
      //   >
      <div className="cards-sm popular-categories__wrapper-item border">
        <div
          class="card-header product-img position-relative overflow-hidden bg-transparent border p-0"
          style={{ height: "150px" }}
        >
          <img
            src={item.image_web}
            alt={item.title}
            className="img-fluid w-100"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            onError={(e) => {
              this.setErrorImg(e.target);
            }}
          />
        </div>
        <div className="card-body text-center" style={{ height: "63px" }}>
          <h5 className="card-title">{item.title}</h5>
        </div>
        <div className="card-body text-center">
          <Link
            data-parentid={item.parent_id}
            className="btn btn-primary"
            to={
              (item.sub_categories.length
                ? "/subcategory/"
                : "/categoryproducts/") + item.id
            }
          >
            View Products
          </Link>
        </div>
        {/* <h5 className="font-body--xl-500">{ item.title }</h5> */}
      </div>
      //   </Link>
    );
  }
}

export default CategoryItem;
